import * as React from "react";

import testimonials from "../images/testimonials.png";
import showCase from "../images/show-case.png";
import testimonialsWebp from "../images/webp/testimonials.webp";
import showCaseWebp from "../images/webp/show-case.webp";

export default function Testimonials() {
    return (
      <div className="client-testimonials two-row">
        <div>
          <h1>Our Clients See Results</h1>
          <picture>
            <source srcSet={testimonialsWebp} type="image/webp" />
            <source srcSet={testimonials} type="image/jpeg" />
            <img loading="lazy" src={testimonials} alt="testimonials" />
          </picture>
        </div>
        <div>
          <p>
            <b>Real Time Core Web Vitals</b>
          </p>
          <picture>
            <source srcSet={showCaseWebp} type="image/webp" />
            <source srcSet={showCase} type="image/jpeg" />
            <img
              loading="lazy"
              src={showCase}
              className="show-case"
              alt="showcase"
            />
          </picture>
        </div>
      </div>
    )
}