import * as React from "react";
import { useState } from "react";

import "../styles/index.css";

import OryForm from "../components/oryForm";
import Header from "../components/header";
import MainBanner from "../components/mainBanner";
import EarlyAccess from "../components/earlyAccess";
import MainContent from "../components/mainContent";
import Testimonials from "../components/testimonials";
import EarlyAccessFooter from "../components/earlyAccessFooter";
import PasswordRecoveryForm from "../components/passwordRecoveryForm";

import useOry from "../hooks/ory";

export default function IndexPage() {
  const [showRecovery, setShowRecovery] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  let [loginFragment, email] =
    typeof window !== "undefined"
      ? window.location.hash.split(":").slice(0, 2)
      : ["", ""];

  const [
    ory,
    sessionLoaded,
    session,
    setSession,
    registerUiSpec,
    loginSpec,
    recoverySpec,
    logoutToken,
    setLogoutToken,
    showRegister,
    setShowRegister,
  ] = useOry(loginFragment === "#register", email);

  return (
    <>
      {/* {session && window.location.replace("/pharos/dashboard")} */}
      <React.Suspense>
        {showRegister ? (
          <OryForm
            uiSpec={registerUiSpec}
            hideForm={() => setShowRegister(false)}
            setSession={setSession}
            onSubmit={(formData) =>
              ory.submitSelfServiceRegistrationFlow(registerUiSpec.id, formData)
            }
            title="Sign Up"
          />
        ) : null}
        {showLogin ? (
          <OryForm
            uiSpec={loginSpec}
            hideForm={() => setShowLogin(false)}
            setSession={setSession}
            onSubmit={(formData) =>
              ory.submitSelfServiceLoginFlow(loginSpec.id, formData)
            }
            additionalControls={
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  setShowLogin(false);
                  setShowRecovery(true);
                  return false;
                }}
              >
                Recover Password
              </a>
            }
            title="Log in"
          />
        ) : null}
        {showRecovery ? (
          <PasswordRecoveryForm
            recoverySpec={recoverySpec}
            setShowRecovery={setShowRecovery}
            ory={ory}
            setSession={setSession}
            setLogoutToken={setLogoutToken}
          />
        ) : null}
      </React.Suspense>
      <Header
        showRegister={() => setShowRegister(true)}
        showLogin={() => setShowLogin(true)}
        ory={ory}
        clearSession={() => setSession(null)}
        logoutToken={logoutToken}
        session={session}
        sessionLoaded={sessionLoaded}
      />
      <MainBanner />
      <React.Suspense>
        {!session ? (
          <EarlyAccess
            showRegister={() => setShowRegister(true)}
            showLogin={() => setShowLogin(true)}
          />
        ) : null}
      </React.Suspense>
      <MainContent />
      <Testimonials />
      <React.Suspense>
        {!session ? (
          <EarlyAccessFooter
            showRegister={() => setShowRegister(true)}
            showLogin={() => setShowLogin(true)}
          />
        ) : null}
      </React.Suspense>
    </>
  );
}
