import * as React from "react";

import logo from "../images/logo.png";
import logoWebp from "../images/webp/logo.webp";

export default function Header({
  session,
  sessionLoaded,
  ory,
  clearSession,
  logoutToken,
  showRegister,
  showLogin,
}) {
  const logout = (event) => {
    event.preventDefault();
    console.log("token", logoutToken);
    ory
      .submitSelfServiceLogoutFlow(logoutToken)
      .then((data) => null)
      .catch((err) => null);
    clearSession();

    return false;
  };

  return (
    <header>
      <a href="https://www.rebelmouse.com/">
        <picture>
          <source srcSet={logoWebp} type="image/webp" />
          <source srcSet={logo} type="image/jpeg" />
          <img width={42} height={42} className="logo" src={logo} alt="Logo" />
        </picture>
        <span className="service-name">RebelMouse Pharos</span>
      </a>
      {sessionLoaded && (
        <React.Suspense fallback={<div />}>
          {!session ? (
            <>
              <button onClick={showRegister}>Sign Up</button>
              <button className="secondary" onClick={showLogin}>
                Login
              </button>
            </>
          ) : (
            <div className="user-info">
              <span className="desktop">
                {`Welcome, ${session.identity.traits.email}`}&nbsp;
              </span>
              <a href="/pharos/dashboard/" className="desktop dashboard">
                Dashboard
              </a>
              <a href="" onClick={logout}>
                Logout
              </a>
            </div>
          )}
        </React.Suspense>
      )}
    </header>
  );
}
