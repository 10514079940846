import { useState, useEffect } from "react";
import { V0alpha2Api, Configuration } from "@ory/client";

export default function useOry(showForm, email) {
  const [showRegister, setShowRegister] = useState(false);

  const [session, setSession] = useState();
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [registerUiSpec, setRegisterUiSpec] = useState();
  const [loginSpec, setLoginSpec] = useState();
  const [recoverySpec, setRecoverySpec] = useState();
  const [logoutToken, setLogoutToken] = useState();

  const basePath =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : "https://auth.pharos.rebelmouse.com";

  const ory = new V0alpha2Api(
    new Configuration({
      basePath,
      baseOptions: {
        withCredentials: true,
      },
    })
  );

  useEffect(() => {
    ory
      .toSession()
      .then(({ data }) => {
        setSession(data);

        ory.createSelfServiceLogoutFlowUrlForBrowsers().then(({ data }) => {
          setSessionLoaded(true);
          setLogoutToken(data.logout_token);
        });
      })
      .catch(() => {
        ory
          .initializeSelfServiceRegistrationFlowForBrowsers()
          .then(function ({ data }) {
            console.log(data);
            setRegisterUiSpec({
              ...data,
              ui: {
                ...data.ui,
                nodes: data.ui.nodes.map(function (node) {
                  if (node.attributes.name == "traits.email") {
                    return {
                      ...node,
                      attributes: { ...node.attributes, value: email },
                    };
                  } else {
                    return node;
                  }
                }),
              },
            });

            if (showForm) {
              setShowRegister(true);
            }

            ory
              .initializeSelfServiceLoginFlowForBrowsers()
              .then(function ({ data }) {
                setSessionLoaded(true);
                setLoginSpec(data);

                ory
                  .initializeSelfServiceRecoveryFlowForBrowsers()
                  .then(function ({ data }) {
                    setRecoverySpec(data);
                  });
              });
          });
      });
  }, []);

  return [
    ory,
    sessionLoaded,
    session,
    setSession,
    registerUiSpec,
    loginSpec,
    recoverySpec,
    logoutToken,
    setLogoutToken,
    showRegister,
    setShowRegister,
  ];
}
