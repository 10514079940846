import * as React from "react";
import { useState } from "react";
import OryForm from "./oryForm";
import { initUserFromNodes } from "../components/oryForm/utils";

const isPasswordSetStep = (formData) => !!formData.password;

export default function PasswordRecoveryForm({
  recoverySpec,
  setShowRecovery,
  ory,
  setSession,
  setLogoutToken,
}) {
  const [settingsSpec, setSettingsSpec] = useState();

  return (
    <OryForm
      uiSpec={recoverySpec}
      hideForm={() => setShowRecovery(false)}
      customAction={(formData, setLoading, setSpec, setUser) => {
        if (isPasswordSetStep(formData)) {
          ory.submitSelfServiceSettingsFlow(settingsSpec.id, formData);
          setShowRecovery(false);
          return false;
        } else {
          ory
            .submitSelfServiceRecoveryFlow(recoverySpec.id, formData)
            .then(({ data }) => {
              setUser(initUserFromNodes(data));
              setLoading(false);
              setSpec(data);
            })
            .catch((err) => {
              if (err.status === 403) {
                setShowRecovery(false);
                return false;
              }
              ory.toSession().then(({ data }) => {
                setSession(data);
                ory
                  .initializeSelfServiceSettingsFlowForBrowsers()
                  .then(({ data }) => {
                    data.ui.nodes = data.ui.nodes.filter(
                      ({ attributes: { type } }) => type !== "email"
                    );
                    setSettingsSpec(data);
                    setUser(initUserFromNodes(data));
                    setSpec(data);
                    setLoading(false);
                  });

                ory
                  .createSelfServiceLogoutFlowUrlForBrowsers()
                  .then(({ data }) => {
                    setLogoutToken(data.logout_token);
                  });
              });
            });
        }
      }}
      title="Recover Account"
    />
  );
}
