import * as React from "react";
import anim from "../images/anim.png";
import animWebp from "../images/webp/anim.webp";

export default function MainContent() {
    return (
      <div className="main-content two-row">
        <div className="anim">
          <picture>
            <source srcSet={animWebp} type="image/webp" />
            <source srcSet={anim} type="image/jpeg" />
            <img
              width={215}
              height={250}
              loading="lazy"
              src={anim}
              alt="optimize your website"
            />
          </picture>
        </div>
        <div>
          <h1>Optimizing Core Web Vitals is Hard. RebelMouse Pharos Makes it Easier.</h1>
          <p>
          Real-time analytical tools that help developers understand if they're actually fixing the problems. Google’s Search Console shows page experience and Core Web Vitals scores, but those scores are an average of 30-days’ worth of user experience data sent from Google’s Chrome browser to Google’s datasets. So it’s very hard for a developer to know if they’ve actually fixed the issue until after waiting many days (and often weeks) to see if the fix has been “validated” by Google. 
          </p>
          <p>
          It is also extremely hard to identify the root cause of failures, such as those related to CLS, which may be caused by behaviors that occur way down a web page or in a user’s behavior timeline.
          </p>
        </div>
      </div>
    )
}