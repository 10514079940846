import * as React from "react";

export default function Wrapper({ attributes, children }) {
    return (
        <div
        key={attributes.name}
        className={attributes.type === "hidden" ? "hidden" : null}
        >
            {children}
        </div>
    )
}