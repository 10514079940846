import * as React from "react";

export default function OryMessages({ messages }) {
  if (messages.length) {
    return (
      <>
        {messages.map((msg) => (
        <div key={msg.text} className={`msg ${msg.type}`}>
          {msg.text}
        </div>
        ))}
      </>
    );
  } else {
    return null;
  }
}
