import * as React from "react";

export default function FormInput({ attributes, meta, user, setUser }) {
  if (attributes.type === "submit") {
    return null;
  }

  return (
    <>
      {attributes.name !== "method" ? (
        <label>{meta.label?.text || ""}</label>
      ) : null}
      <input
        value={user[attributes.name]}
        onChange={(event) =>
          setUser({ ...user, [attributes.name]: event.target.value })
        }
        type={attributes.name === "method" ? "hidden" : attributes.type}
      />
    </>
  );
}
