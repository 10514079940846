import * as React from "react";
import { useState, useEffect } from "react";
import Buttons from "./buttons";
import FormInput from "./formInput";
import OryMessages from "./messages";
import SocialProviderButton from "./socialProviderButton";
import Wrapper from "./wrapper";
import { initUserFromNodes, isSocialProvider } from "./utils";

export default function OryForm({
  uiSpec,
  hideForm,
  title,
  setSession,
  onSubmit,
  additionalControls,
  customAction,
}) {
  const [user, setUser] = useState(initUserFromNodes(uiSpec));
  const [loading, setLoading] = useState(false);
  const [spec, setSpec] = useState(uiSpec);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    onSubmit &&
      onSubmit(user)
        .then((res) => {
          setLoading(false);
          setSession(res.data.session);
          hideForm();
        })
        .catch((err) => {
          setLoading(false);
          setSpec(err.response.data);
          setUser(initUserFromNodes(err.response.data));
        });

    customAction && customAction(user, setLoading, setSpec, setUser);
  };

  if (!uiSpec) {
    return null;
  }

  return (
    <div className="form-wrapper" onClick={hideForm}>
      <div className="curtain"></div>
      <form
        onSubmit={handleSubmit}
        onClick={(event) => event.stopPropagation()}
        className="register-form"
      >
        <h2>{title}</h2>
        <OryMessages messages={spec?.ui?.messages || []} />
        {(spec?.ui?.nodes || []).map(({ attributes, meta, messages }) => (
          <Wrapper
            key={`${attributes.type}:${attributes.name}`}
            attributes={attributes}
          >
            {isSocialProvider(attributes) ? (
              <SocialProviderButton
                attributes={attributes}
                meta={meta}
                uiSpec={uiSpec}
              />
            ) : (
              <>
                <OryMessages messages={messages} />
                <FormInput
                  attributes={attributes}
                  meta={meta}
                  user={user}
                  setUser={setUser}
                />
              </>
            )}
          </Wrapper>
        ))}
        {additionalControls ? (
          <>
            {additionalControls}
            <br />
            <br />
          </>
        ) : null}
        <Buttons
          title={title}
          loading={loading}
          handleSubmit={handleSubmit}
          hideForm={hideForm}
        />
      </form>
    </div>
  );
}
