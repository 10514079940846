import * as React from "react";

export default function Buttons({ loading, handleSubmit, hideForm, title }) {
  return (
    <>
      <button className={loading ? "loading medium" : "medium"} onClick={handleSubmit}>
        {title}
      </button>
      &nbsp;&nbsp;
      <button className="secondary medium" onClick={hideForm}>
        Cancel
      </button>
    </>
  );
}
