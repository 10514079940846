export function initUserFromNodes(uiSpec) {
  return uiSpec?.ui?.nodes
    ? Array.from(uiSpec.ui.nodes).reduce((acc, { attributes }) => {
        if (attributes.type === "submit" && attributes.name === "email") {
          return acc;
        } else {
          return { ...acc, [attributes.name]: attributes.value || "" };
        }
      }, {})
    : {};
}

export function isSocialProvider(attributes) {
  return attributes.type === "submit" && attributes.name === "provider";
}
