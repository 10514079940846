import * as React from "react";

import logoExt from "../images/logo-ext.png";
import iphone from "../images/iPhone11Pro.png";
import logoExtWebp from "../images/webp/logo-ext.webp";
import iphoneWebp from "../images/webp/iPhone11Pro.webp";

export default function MainBanner() {
    return (
      <div className="main-banner">
        <div className="baseline">
          <div className="text-container">
            <p>
              RebelMouse&#39;s <b>Pharos:</b> Real-time Core Web Vitals monitoring
            </p>
            <div>
              <em>
              Real-Time Analysis, Monitoring, and Intelligence for Core Web Vitals
              </em>
            </div>
          </div>
        </div>
        <div className="banner-animation">
          <picture>
            <source srcSet={logoExtWebp} type="image/webp" />
            <source srcSet={logoExt} type="image/jpeg" />
            <img
              width={215}
              height={272}
              src={logoExt}
              alt="logo pharos"
              className="logo-ext"
            />
          </picture>
          <picture>
            <source srcSet={iphoneWebp} type="image/webp" />
            <source srcSet={iphone} type="image/jpeg" />
            <img
              width={252}
              height={428}
              src={iphone}
              alt="phone"
              className="phone"
            />
          </picture>
        </div>
      </div>
    )
}