import * as React from "react";

export default function EarlyAccessFooter({ showRegister, showLogin }) {
    return (
      <div className="early-access-block">
        <div className="content-wrapper">
          <p>
            Free For Early Adopters During Our Limited <span>Beta</span> Period
          </p>
          <button onClick={showRegister}>Sign Up</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button className="secondary" onClick={showLogin}>Login</button>
        </div>
      </div>
    )
}