import * as React from "react";

export default function SocialProviderButton({ attributes, meta, uiSpec }) {
  const handleSocialAuth = (event) => {
    event.preventDefault();

    const form = event.target.parentElement.parentElement;
    form.action = uiSpec.ui.action;
    form.method = uiSpec.ui.method;
    form.submit();
  };
  return (
    <>
      <input type="hidden" name="provider" value={attributes.value} />
      <a
        href=""
        data-provider-type={attributes.value}
        onClick={handleSocialAuth}
      >
        {meta.label.text}
      </a>
    </>
  );
}
